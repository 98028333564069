import React, { useState } from 'react'
import { Menu, LangSelect } from './constants'
import { Button } from '../Button'
import { Logo } from '../Logo'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Header = ({ children, black, blackResponsive }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const toggleMenuOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <header className="header container" style={{ padding: '15px 0 15px 0' }}>
      {black ? (
        <div className="top-bar ">
          <div className="d-flex align-items-center">
            <div className="top-bar__logo mr-auto">
              <Logo black={true} blackResponsive={blackResponsive} />
            </div>

            <div className="top-bar__language form-group inline select-blue">
              <LangSelect />
            </div>

            <Menu
              blackResponsive={blackResponsive}
              toggleMenuOpen={toggleMenuOpen}
              isOpen={isOpen}
            />
          </div>
        </div>
      ) : (
        <div className="top-bar ">
          <div className="top-bar__logo mr-auto">
            <Logo black={false} />
          </div>

          <div className="top-bar__language form-group inline select-blue">
            <LangSelect />
          </div>

          <Menu toggleMenuOpen={toggleMenuOpen} isOpen={isOpen} />
        </div>
      )}
      {children}
    </header>
  )
}

export default Header
