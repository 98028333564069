import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Link } from 'gatsby'

const Success = () => {
  const { t } = useTranslation()

  return (
    <div className="container pt-9 pb-9">
      <p className="pt-9 pb-9">{t('form_success')}</p>
      <Link
        to="/"
        aria-hidden="true"
        className="btn btn-primary slider__btn btn-video mb-9"
      >
        {t('form_success_btn')}
      </Link>
    </div>
  )
}

export default Success
