import React from 'react'

const Banner = ({ title, className }) => {
  return (
    <div className="banner">
      <div className="p-absolute">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className={`banner__title white text-center ${className}`}>
                {title}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
